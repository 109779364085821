<template>
    <div class="container">
        <!-- <div class="block">
            <el-carousel height="600px" :autoplay="false">
                <el-carousel-item v-for="item in 3" :key="item">
                <h3 class="medium">{{ item }}</h3>
                </el-carousel-item>
            </el-carousel>
        </div> -->
        <div class="box" style="overflow:hidden">
            <h1 style="text-align:center;font-weight:500">常见问题</h1>
            <div style="overflow:hidden">
                <div class="item">
                    <h3>
                        <div class="question"><span>问</span></div>
                        网关的并发能力怎么样？
                    </h3>
                    <div class="answer_p">
                        <div class="answer"><span>答</span></div>
                        <p>
                        网关底层基于Linux提供的Epoll能力，采用IO多路复用技术和协程技术提高并发能力，单个网关可以支持200百个末端设备的不间隔轮询采集数据，最新表头数据可以永久存储，防止掉电丢数据，并可以在1s（可配置）内检测到设备是否断电离线。关于查询能力，具体的数据和硬件配置有关，HLGT-EMOka系列网关支持多种硬件配置，其中最低配的可以达到并发查询能力在1000次每秒以上。
                        </p>
                    </div>
                  
                </div>
                <div class="item">
                    <h3>上位机软件的数据存储能力如何？
                        <div class="question"><span>问</span></div>
                    </h3>
                    <div class="answer_p">
                        <div class="answer"><span>答</span></div>
                        <p>上位机软件采用我们公司自研的列式存储数据库，每秒可以达到几万的数据写入，具体数据和硬件配置有关，由于采用列式存储数据压缩率极高，可以节省大量的磁盘空间，物联网的终端联网设备数量极其庞大，并且会一天24小时不间断上报数据，会产生大量的数据，而上报的数据大部分是数值等小数据块，传统的关系型数据块例如Mysql是无法存储这种数据的，针对这种情况我们自研数据库，底层采用列式存储，上层封装一层写入缓冲区汇聚数据以大批量写入，能够每秒有10w级别的写入速度，每个列上做数据压缩，节约大量的存储成本，查询层支持任意条件组合查询和随机分页。</p>
                    </div>
                </div>
                <div class="item">
                    <h3 >上位机软件的并发能力如何？
                        <div class="question"><span>问</span></div>
                    </h3>
                    <div class="answer_p">
                        <div class="answer"><span>答</span></div>
                        <p>我们专门针对物联网，开发了专用的语言和专用的开发框架，提供协程化基础，每个请求开一个轻量级的协程，大幅度提高并发性非常适合IOT设备，框架提供了所有基础协议的封装，基于这个语言和框架我们大幅度的提高了开发效率，提升了业务稳定性，以适应IOT的需求。我们还研发了字节码优化技术，来提高程序的性能，并提高程序的安全性，他可以把脚本语言的字节码优化、精简，每一行代码会经过11轮的pass，比如流程优化、常量传播、常量折叠等等，进行了大量的的优化、精简、大幅度提高性能，每秒的处理能力可以达到10万TPS。</p>
                    </div>
                </div>
                <div class="item">
                    <h3 >通讯管理机使用便捷性如何？
                        <div class="question"><span>问</span></div>
                    </h3>
                    <ul>
                        <div class="answer" style="top:-3px;left:0"><span>答</span></div>
                        <li>支持IP、 子掩码、 网关配置</li>
                        <li>支持采集端与转发端同时配置</li>
                        <li>支持EXCLE导出及导入，会使用EXCLE，即可配置网关</li>
                        <li>支持修改后的配置文件自动覆盖原配置文件</li>
                        <li>支持不同网关，同一配置共享，方便快捷</li>
                        <li>支持远程配置，减少出差次数</li>
                    </ul>
                </div>
                <div class="item">
                    <h3 >通讯管理机支持的接口与协议有哪些？
                        <div class="question"><span>问</span></div>

                    </h3>
                    <ul>
                        <div class="answer" style="top:-3px;left:0"><span>答</span></div>

                        <li>支持RS584、RS232、RS422等接口</li>
                        <li>采集端支持Modbus协议、DLT645-2007协议、BACnet协议、OPC协议等常用工业楼宇协议</li>
                        <li>采集端支持104规约、103规约、102规约、102规约等电力规约</li>
                        <li>采集端支持188、mbus等水表协议</li>
                        <li>采集端支持IOT（物联网） MQTT协议</li>
                        <li>采集端支lonwork---lontalk协议</li>
                        <li>转发端支持Modbus TCP/IP、BACnet TCP/IP、MQTT、OPC_Server等协议</li>
                        <li>支持私有协议开发</li>
                    </ul>
                </div>
                <div class="item">
                    <h3>通讯管理机使用过程中常见问题及注意事项？
                        <div class="question"><span>问</span></div>
                    </h3>
                    <h4>1、修改通讯管理机属性参数后，失去连接?
                    </h4>
                    <div class="answer_p" style="margin-left:35px">
                        <div class="answer" style="top:-3px;left:0"><span>答</span></div>
                        <p style="padding-left:3%">修改通讯管理机IP、子掩码、网关等基础参数后，需要重新给通讯管理机上电，新设置的参数才能生效。</p>
                    </div>
                    <h4>2、使用过程中的注意事项有哪些？</h4>
                    <ul style="padding-left:7.5%">
                        <div class="answer" style="top:-3px;left:35px"><span>答</span></div>
                        <li>网口1用于连接外网方便远程调试、网口2连接内网用于数据上传至上位机。</li>
                        <li>修改网口IP时，务必记住修改的网口，否则将失去与通讯管理机的连接。</li>
                        <li>通讯接口禁止连接36V及以上电源接触电源，否则可能会造成该接口损坏或整个设备损坏。</li>
                    </ul>
                    <h4 >3、通讯管理机采集数据与现场设备数据偏差较大？</h4>
                     <div class="answer_p" style="margin-left:35px">
                        <div class="answer" style="top:-3px;left:0"><span>答</span></div>
                        <p style="padding-left:3%">优先考虑字节序配置错误，检查采集与转发字节序。</p>
                    </div>
                    <h4 >4、通讯管理机采集不到数据？</h4>
                    <div class="answer_p" style="margin-left:35px">
                        <div class="answer" style="top:-3px;left:0"><span>答</span></div>
                        <p style="padding-left:3%">优先检查线路是否存在问题、再检查配置属性是否错误。</p>
                    </div>
                    <h4 >5、通讯管理机采集到数据、过了一会又采集不到,反复出现上述情况？</h4>
                    <div class="answer_p" style="margin-left:35px">
                        <div class="answer" style="top:-3px;left:0"><span>答</span></div>
                        <p style="padding-left:3%">优先考虑线路过远、受到干扰。末端增加200欧电阻或增加中继模块放大信号。</p>
                    </div>
                    <h4 >6、通讯管理机采集数据正常、转发异常？</h4>
                    <div class="answer_p" style="margin-left:35px">
                        <div class="answer" style="top:-3px;left:0"><span>答</span></div>
                        <p style="padding-left:3%">优先检查上位机与管理机对应是否错误，检查上传协议是否错误。</p>
                    </div>
                </div>
                <div class="item">
                    <h3>当网关无法读取上来数据 ？
                        <div class="question"><span>问</span></div>
                    </h3>
                    <div class="answer_p">
                        <div class="answer"><span>答</span></div>
                        <p>可以尝试调大超时时间再尝试</p>
                    </div>
                </div>
                <!-- <div class="item">
                    <h3 >通讯管理机与设备层通讯异常的几种情况？
                        <div class="question"><span>问</span></div>
                    </h3>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageY:0
        }
    },
    created(){
    },
    mounted(){
        window.addEventListener("scroll", this.handleScroll, true);
    },
    methods:{
        handleScroll() {
            // console.log("滚动高度", window.pageYOffset);
            this.pageY = window.pageYOffset;
        }
    }
}
</script>
<style lang="scss" scoped>
.question{
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    background:#e40b18;
    position: absolute;
    top: 0;
    left: -10px;
}
.answer_p{
    margin-left: 5px;
    position: relative;
}
.answer{
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    background:#E5690B;
    position: absolute;
    top: -3px;
    left: -5px;
}
h3{
    font-weight: 500;
    position: relative;
    margin-left: 10px;

    
}
h4{
    font-weight: 500;
    position: relative;
    font-size: 15px;
}
li{
    list-style: decimal;
    line-height: 1.5em;
    font-size: 15px;
}
ul{
    padding-left: 7%;
    position: relative;
}
.animat_down{
    animation: down 1s ease-in-out 1;
}
.animat_left{
    animation: left 1s ease-in-out 1;
}   
.animat_right{
    animation: right 1s ease-in-out 1;
}   
@keyframes right{
   0% {
      transform: translateX(2000px);
      opacity: 0;
   }
   100% {
      transform: translateX(0px);
      opacity: 1;
   }
}
@keyframes left{
   0% {
      transform: translateX(-2000px);
      opacity: 0;
   }
   100% {
      transform: translateX(0px);
      opacity: 1;
   }
}
@keyframes up{
   0% {
      transform: translateY(-2000px);
      opacity: 0;
   }
   100% {
      transform: translateX(0px);
      opacity: 1;
   }
}

@keyframes down{
   0% {
      transform: translateY(-2000px) translateX(2000px);
      opacity: 0;
   }
   100% {
      transform: translateX(0px);
      opacity: 1;
   }
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.container{
    width: 100%;
    .box{
        width: 1100px;
        transform: scale(0.98);
        padding: 1% 5%;
        margin: 3% auto;
        background: hsl(0, 0%, 97%,0.1);
        border-radius: 12px;
        h3{
            padding-left: 2%;
        }
        h4{
            padding-left: 4%;
        }
        p{
            line-height: 1.5em;
            font-size: 15px;
            padding-left: 27px;
        }
        .item{
            border-bottom: 1px solid #ebebeb;
        }
    }
}
</style>