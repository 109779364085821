<template>
    <div class="container">
        <div class="box">
            <div class="item-list">
                <div class="item">
                    <div class="img animat_right">
                        <img src="@/assets/case/01.jpg" alt="">
                    </div>
                    <div class="text animat_left">
                        <h1>全维度综合决策管理系统</h1>
                        <div style="width:60%;text-align:left;padding-left:155px">
                            <p></p>
                            <ul>
                                <li>直面用户需求</li>
                                <li>集中可视化的管理</li>
                                <li>支持各种个性化专业化定制如：智慧养老、智慧医院、智慧园区</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="text animat_right">
                        <h1>工厂生产车间气环境净化、治理及智能控制系统解决方案</h1>
                        <div style="width:95%;text-align:left;padding-left:90px">
                            <ul>
                                <li>实时采集车间内空气质量参数</li>
                                <li>灵活地进行科学化的定制设计、设置、组合，具有广泛的适用性</li>
                                <li>采用内循环的处理方式，不影响车间生产环境工况</li>
                                <li>集中可视化的管理</li>
                            </ul>
                        </div>
                    </div>
                    <div class="img animat_left">
                        <img src="@/assets/case/02.jpg" alt="">
                    </div>
                </div>
                <div class="item">
                    <div class="img" :class="pageY>99?'animat_right':''">
                        <img src="@/assets/case/03.jpg" alt="">
                    </div>
                    <div class="text" :class="pageY>103?'animat_left':''">
                        <h1>蓄能（蓄冰/蓄热）全维度中央调控系统解决方案</h1>
                        <div style="width:90%;text-align:left;padding-left:90px">
                            <ul>
                                <li>整个蓄能中央空调系统在夜间自动开启制冰蓄冷/制热蓄热,以供全天使用，实现
了低成本制冷/制热的用能</li>
                                <li>工作模式：新风模式、防疫模式、制冷模式、制热模式等</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="text"  :class="pageY>455?'animat_right':''">
                        <h1>智慧楼宇全维度综合决策管理系统云平台解决方案</h1>
                        <div style="width:94%;text-align:left;padding-left:90px">
                            <ul>
                                <li>面向客户有机集成、集中，以客户的需求为出发点，集成了事件处理功能模块，并接受各种定制，让物业的管理更简单、更智能、更专业、更科学，让智慧楼宇的运行管理，真正变得简单、智慧。</li>
                            </ul>
                        </div>
                    </div>
                    <div class="img"  :class="pageY>425?'animat_left':''">
                        <img src="@/assets/case/04.png" alt="">
                    </div>
                </div>
                <div class="item">
                    <div class="img" style="padding-top:3%"  :class="pageY>830?'animat_left':''" > 
                        <img src="@/assets/case/05.jpg" alt="">
                    </div>
                    <div class="text"  :class="pageY>845?'animat_right':''">
                        <h1>能效管理系统解决方案</h1>
                        <div style="width:91%">
                            <ul>
                                <li>监控中心，完成整个系统的数据显示、处理、监控、及管理功能,并对相应管理数据，进行对比分析、排名分析，以及自动生成能耗
成本的饼状图和实时动态曲线。</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="item" >
                    <div class="text"  :class="pageY>1150?'animat_right':''" >
                        <h1>计量预收费控制管理系统解决方案</h1>
                        <div style="width:70%;text-align:left;padding-left:90px">
                            <ul>
                                <li>快速配置，即装即用</li>
                                <li>远程集中抄表，免去人工抄表</li>
                                <li>支持单独计价、多费率</li>
                                <li>远程售电，财务集中管理</li>
                                <li>手机短信提醒</li>
                                <li>根据设置，远程控制</li>
                                <li>能耗分析与查询</li>
                            </ul>
                        </div>
                    </div>
                    <div class="img"  :class="pageY>1120?'animat_left':''" >
                        <img src="@/assets/case/06.jpg" alt="">
                    </div>
                </div>
                <div class="item">
                    <div class="img"  :class="pageY>1550?'animat_right':''" >
                        <img src="@/assets/case/07.jpg" alt="">
                    </div>
                    <div class="text"  :class="pageY>1570?'animat_left':''" >
                        <h1>中央空调能耗计量收费管理系统解决方案</h1>
                        <div style="width:70%">
                            <ul>
                                <li>在线进行实时动态计量，系统支持浮动单价和固定单价两种计费方式，也支持预收费和后付费两种收费方式，系统提供丰富的报表格式，以及各种订制化功能需求。</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="text"  :class="pageY>1900?'animat_right':''" >
                        <h1>多联机空调能耗计量收费管理系统解决方案</h1>
                        <div style="width:75%">
                            <ul>
                                <li>实时采集VRV空调能耗的分户状态，并实时对分户的能耗进行记录和分滩，最终实现按天/月，科学分摊外机能耗的现实要求。</li>
                            </ul>
                        </div>
                    </div>
                    <div class="img"  :class="pageY>1890?'animat_left':''" >
                        <img src="@/assets/case/08.jpg" alt="">
                    </div>
                </div>
                <div class="item">
                    <div class="img"  :class="pageY>2178?'animat_right':''" >
                        <img src="@/assets/case/09.jpg" alt="">
                    </div>
                    <div class="text"  :class="pageY>2190?'animat_left':''" >
                        <h1>楼宇自动化控制与管理系统</h1>
                        <div style="width:100%">
                            <ul>
                                <li>系统对各个建筑内各自辖区内的冷热源、空调、给排水、供变配电、照明、电梯等建筑设备及室外公共场所的道路照明、建筑物的景观照明等进行集中管理，分散控制</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="text"  :class="pageY>2500?'animat_right':''" >
                        <h1>智能照明控制系统解决方案</h1>
                        <div style="width:78%">
                            <ul>
                                <li>智能照明控制系统，不仅在现场可以控制各个区域的灯光，还可通过中央监控系统对照明状况进行监控，既方便了物业管理又可节约能源。</li>
                            </ul>
                        </div>
                    </div>
                    <div class="img"  :class="pageY>2440?'animat_left':''" >
                        <img src="@/assets/case/10.jpg" alt="">
                    </div>
                </div>
                <div class="item">
                    <div class="img"  :class="pageY>2700?'animat_right':''" >
                        <img src="@/assets/case/11.jpg" alt="">
                    </div>
                    <div class="text"  :class="pageY>2710?'animat_left':''" >
                        <h1>视频监控管理系统解决方案</h1>
                        <div style="width:73%;text-align:left;padding-left:205px">
                            <ul>
                                <li>支持实时监控</li>
                                <li>支持录像回放</li>
                                <li>支持分级、分区域及多用户管理；提供用户权限管理</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="text"  :class="pageY>3000?'animat_right':''" >
                        <h1>停车场收费管理系统解决方案</h1>
                        <div style="width:60%;text-align:left;padding-left:90px">
                            <ul>
                                <li>视频识别车辆，实现免取卡秒速进场。</li>
                                <li>通过车牌识别核算停车费</li>
                                <li>提升停车场的信息化功能</li>
                            </ul>
                        </div>
                    </div>
                    <div class="img"  :class="pageY>3000?'animat_left':''" >
                        <img src="@/assets/case/12.jpg" alt="">
                    </div>
                </div>
                <div class="item">
                    <div class="img"  :class="pageY>3280?'animat_right':''" >
                        <img src="@/assets/case/13.jpg" alt="">
                    </div>
                    <div class="text"  :class="pageY>3295?'animat_left':''" >
                        <h1>洁净室尘埃粒子数量动态监测系统解决方案</h1>
                        <div style="width:79%;padding-left: 18px;">
                            <ul>
                                <li>监测粒子、风速、温湿度、差压、浮游菌等；</li>
                                <li>可对数据进行上限设置，实现声光预警及报警功能；</li>
                                <li>系统软件可运行于局域网、根据访问权限进行设置和查看</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="text"  :class="pageY>3690?'animat_right':''" >
                        <h1>消防类系统解决方案</h1>
                        <div style="width:50%;padding-left: 90px;">
                            <ul>
                                <h3><li style="list-style-type:disc">消防设备电源监控系统</li></h3>
                                <h3><li style="list-style-type:disc">电气火灾报警系统</li></h3>
                                <h3><li style="list-style-type:disc">集中电源式应急照明疏散系统</li></h3>
                            </ul>
                        </div>
                    </div>
                    <div class="img"  :class="pageY>3675?'animat_left':''" >
                        <img src="@/assets/case/14.jpg" alt="">
                    </div>
                </div>
                <div class="item">
                    <div class="img"  :class="pageY>3985?'animat_right':''" >
                        <img src="@/assets/case/17.jpg" alt="">
                    </div>
                    <div class="text"  :class="pageY>4000?'animat_left':''" >
                        <h1>工厂、商业、农业、园区、市政等行业云端解决方案</h1>
                        <div style="width:85%;padding-left: 30px;">
                            <ul>
                                <li>河洛工业物联网整体解决方案帮助企业实现数字化转型，整合工厂信息系统数据，提高企业整体运营效率，减小企业运行成本。可广泛应用于智能充电桩、智能农业、智慧工厂、智慧光伏、智慧养殖、智能消防等各行业。</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageY:0
        }
    },
     mounted(){
        window.addEventListener("scroll", this.handleScroll, true);
    },
    methods:{
        handleScroll() {
            this.pageY = window.pageYOffset;
            // console.log("滚动高度", window.pageYOffset);
        }
    }
}
</script>
<style lang="scss" scoped>
.animat_down{
    animation: down 1s ease-in-out 1;
}
.animat_left{
    animation: left 1s ease-in-out 1;
}   
.animat_right{
    animation: right 1s ease-in-out 1;
}   
@keyframes right{
   0% {
      transform: translateX(2000px);
      opacity: 0;
   }
   100% {
      transform: translateX(0px);
      opacity: 1;
   }
}
@keyframes left{
   0% {
      transform: translateX(-2000px);
      opacity: 0;
   }
   100% {
      transform: translateX(0px) translateY(0px);
      opacity: 1;
   }
}
@keyframes up{
   0% {
      transform: translateY(-2000px);
      opacity: 0;
   }
   100% {
      transform: translateX(0px);
      opacity: 1;
   }
}

@keyframes down{
   0% {
      transform: translateY(-2000px) translateX(2000px);
      opacity: 0;
   }
   100% {
      transform: translateX(0px);
      opacity: 1;
   }
}
li{
    list-style: none;
    font-size: 20px;
    margin: 2% 0;
}
.container{
    margin-top: 55px;
    width: 100%;
    .box{
        width: 100%;
    }
    .item-list{
        .item{
            display: flex;
            padding: 2% 8%;
            // margin: 2% 0;
            justify-content: center;
            overflow: hidden;
            ul{
                padding: 0;
            }
            li{
                line-height: 1.5em;
            }
            .img{
                width: 60%;
                text-align: center;
                img{
                    width: 70%;
                }
            }
            .text{
                width: 60%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                h1{
                    font-weight: 500;
                }
                div{
                    width: 40%;
                }
                p{
                    font-size: 20px;
                    text-align: left;
                }
            }
        }
    }
}
</style>