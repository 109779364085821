<template>
  <div class="container">
    <div class="block">
      <el-carousel height="489px" :interval="4000">
        <el-carousel-item v-for="item in img_arr" :key="item.id">
          <img :src="item.url" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="box">
      <div class="content" style="margin-top:30px">
        <h1 class="">
          <a style="color:#c0012c;font-size:27px;font-weight:400" href="javaScript:void(0)">HLGT-IDMS系统优势</a>
        </h1>
        <span style="color:#c0012c;font-size:16px">Advantages of hlgt-idms system</span>
      </div>
      <div style="width:100%;background:#ebebed;text-align:center;height:465px">
        <img style="width:70%;padding:20px 0" src="~@/assets/system/02.png" alt="">
      </div>
      <div class="content" style="margin-top:30px">
        <h1 class="">
          <a style="color:#c0012c;font-size:27px;font-weight:400" href="javaScript:void(0)">系统简介</a>
        </h1>
        <span style="color:#c0012c;font-size:16px">System introduction</span>
      </div>
      <div class="box_content">
        <div style="margin:0 15%">
          <div style="padding-top:5%">
            <h3>主要功能</h3>
            <p>高度模块化的系统有机集成，各功能模块既相互独立，又有机集成，保证所有智能设备的运行状态，被统一监管，集中报警显示、处理，管理更加便捷，所有智能设备的数据，实时刷新显示——底层智能设备的通讯状态监控及数据运行监控</p>
            <p>丰富的管理员总界面/分界面，各功能模块在总界面集中重点监测显示、报警、处理和管理主界面中拥有多个自由切换的动态子界面，确保总界面监控的全面高效，并支持主界面的定制</p>
          </div>
          <div style="padding-top:5%">
            <h3>系统架构</h3>
            <p>HLGT-IDMS全维度综合决策管理系统，是一款基于大数据基础平台的及实时数据交互平台的，是面向用户管理需求的，并支持用户对各种管理功能进行大量定制的，具有优秀的数据安全、数据可视化、数据计算、数据分析功能，且具有集中化监管和流程化管理功能的系统集成管理平台，且系统支持云端部署和本地化部署。</p>
          </div>
        </div>
        <div class="item-list" style="margin-bottom:3%">
          <div class="item mar">
            <h3 style="background: linear-gradient(to bottom,#4003c2,#6f4dfc);">能源管理</h3>
            <img src="~@/assets/system/item1.png" alt="" @click="url = require('@/assets/system/item1.png')">
            <h4>项目面积等分析数据手动输入设置。分析界面自动生成功能</h4>
          </div>
          <div class="item mar">
            <h3 style="background: linear-gradient(to bottom,#007f9c,#0fbee9);">楼宇自控</h3>
            <img src="~@/assets/system/item2.jpg" alt="" @click="url = require('@/assets/system/item2.jpg')">
            <h4>各种传感设备运行参数的实时采集，支持各种控制模式的远程切换</h4>
          </div>
          <div class="item mar">
            <h3 style="background: linear-gradient(to bottom,#0051e9,#62a5fc);">在线监测</h3>
            <img src="~@/assets/system/item3.png" alt=""  @click="url = require('@/assets/system/item3.png')">
            <h4>支持各种传感器数据的实时动态采集、显示、报警，及相关计算、分析功能</h4>
          </div>
          <div class="item">
            <h3 style="background: linear-gradient(to bottom,#ff8a16,#fec901);">智能照明</h3>
            <img src="~@/assets/system/item4.jpg" alt="" @click="url = require('@/assets/system/item4.jpg')">
            <h4>界面显示每个灯控模块的通讯状态极其控制区域下各回路的开关状态</h4>
          </div>
        </div>
        <div class="box" style="background:#ebebed;padding-bottom:1%">
          <div style="display:flex;justify-content: center;margin:0 16.3%">
            <img style="width:100%;border:20px solid #d3d3d5" :src="url" alt="">
          </div>
          <div class="item-list" style="margin-bottom:3%">
            <div class="item mar">
              <h3 style="background: linear-gradient(to bottom,#19ad00,#12fb01);">事件弹窗</h3>
              <img src="~@/assets/system/item5.jpg" alt="" @click="url2 = require('@/assets/system/item5.jpg')">
              <h4>项目面积等分析数据手动输入设置。分析界面自动生成功能</h4>
            </div>
            <div class="item mar">
              <h3 style="background: linear-gradient(to bottom,#aa0081,#ee73b2);">设备监测</h3>
              <img src="~@/assets/system/item6.png" alt="" @click="url2 = require('@/assets/system/item6.png')">
              <h4>各种传感设备运行参数的实时采集，支持各种控制模式的远程切换</h4>
            </div>
            <div class="item mar">
              <h3 style="background: linear-gradient(to bottom,#913601,#e1a602);">视频监控</h3>
              <img src="~@/assets/system/item7.jpg" alt="" @click="url2 = require('@/assets/system/item7.jpg')">
              <h4>支持各种传感器数据的实时动态采集、显示、报警，及相关计算、分析功能</h4>
            </div>
            <div class="item">
              <h3 style="background: linear-gradient(to bottom,#5801bf,#a924f9);">消防接入</h3>
              <img src="~@/assets/system/item8.jpg" alt="" @click="url2 = require('@/assets/system/item8.jpg')">
              <h4>界面显示每个灯控模块的通讯状态极其控制区域下各回路的开关状态</h4>
            </div>
          </div>
          <div style="display:flex;justify-content: center;margin:0 16.3% 5%">
            <img style="width:100%;border:20px solid #d3d3d5" :src="url2" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      url:require('@/assets/system/item1.png'),
      url2:require('@/assets/system/item5.jpg'),
      active: 0,
      active1: 0,
      pageY: 0,
      show: false,
      img_arr:[
        {id:0,url:require('@/assets/banner/01.jpg')},
        {id:1,url:require('@/assets/banner/02.jpg')},
        {id:2,url:require('@/assets/banner/03.jpg')},
        {id:3,url:require('@/assets/banner/04.jpg')},
      ]
    };
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    change(index) {
      this.active1 = index;
    },
    changeIndex(index) {
      this.active = index;
    },
    handleScroll() {
      this.pageY = window.pageYOffset;
    },
  },
};
</script>
<style lang="scss" scoped>
.mar{
  margin-right:5%;
}
.block{
  margin-top: 59px;
  img{
    width: 100%;
  }
}
a {
  color: #333;
}
li {
  line-height: 1.5em;
}
.container {
  h1{
    margin-bottom: 0;
  }
  .box_content{
    background:url('~@/assets/system/bg.jpg') no-repeat;
    background-size: 100%;
    p{
      font-size: 14px;
      line-height: 1.8em;
    }
  }
  .item-list{
    margin: 0 15%;
    display: flex;
    justify-content: center;
    .item{
      width: 25%;
      img{
        width: 100%;
        transition: all 0.5s linear;
      }
      img:hover{
        cursor: pointer;
      }
      h4{
        font-size: 14px;
        margin-top: 5px;
        color: #333;
      }
    }
    h3{
      color: #fff;
      font-weight: 300;
      text-align: center;
      font-size: 14;
      padding: 5px 0;
      
    }
  }
}
.content {
  text-align: center;
  position: relative;
  border-bottom: 3px solid #6ab0e1;
  h1 {
    font-weight: 500;
    margin-top: 0;
  }
  span {
    width: 100%;
    display: inline-block;
    padding-bottom: 5px;
  }
}
.content::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 37.5%;
  margin: -1px;
  width: 25%;
  height: 2px;
  background: #fe0000;
  z-index: 999;
}
</style>