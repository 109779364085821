<template>
  <div class="container">
    <div class="block">
      <el-carousel height="489px" :interval="4000">
        <el-carousel-item v-for="item in img_arr" :key="item.id">
          <img :src="item.url" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="main">
      <div class="content" style="margin-top:25px">
        <h1
          class="animate__animated"
          style="margin-bottom: 0px"
        >
          <a style="color:#c0012c;font-size:27px" href="javaScript:void(0)">工业级智能网关与网络引擎控制管理机</a>
        </h1>
        <span
          style="color:#c0012c;font-size:16px"
          class="animate__animated"
          >Industrial intelligent gateway and network engine control manager</span
        >
      </div>
      <img style="width:100%" src="~@/assets/gateway/gateway1.png" alt="">
      <div class="box">
        <h3>工业级HLGT-EMOka系列智能通讯网关</h3>
       
        <p> HLGT-EMOka系列可编程智能网关，采用arm架构，嵌入式Linux实时多任务操作系统，实现各种智能化通讯设备和系统主机间的信息传递、合成、变成、管理、以及设备监测、控制、管理等功能。</p>
        <img style="width:90%;margin:0 auto" src="~@/assets/gateway/gateway3.png" alt="">
      </div>
      <div class="bg">
        <div class="top-item-total box" style="width:950px">
          <div class="item-list">
            <div class="item">
              <div class="left">
                <h2 style="margin-top:0">多种协议转换（可定制）</h2>
                <p>支持多种通讯协议自由转换，实现不同现场网络间的无缝衔接。</p>
                <p>Modbus TCP,EtherNet/IP,ProfiNet,EtherCAP,BACNet,LonWorks,CAN/CANOpen,Profibus,M-BUS,DTL645,CJT188,HTJ212,IEC61850,IEC60870-103/104,IEC60870-101/102,CCN,SNMP,OPC,MQTT,HART。</p>
              </div>
              <div class="right">
                <img style="width:100%;" src="~@/assets/gateway/04.png" alt="">
              </div>
            </div>
            <div class="item">
              <div class="left">
                <h2 style="margin-top:0">傻瓜式配置，快速实施</h2>
                <p>支持通过Excel文件上传的方式，实现对网关的批量配置，简单、快速、高效。</p>
                <p>网关所有的配置下发，编程逻辑下发，以及调试都支持通过简易的B/S架构方式，只需插上网线，访问网关IP的8080端口，就可以进行所有的操作，无需安装客户端,无需任何学习成本。</p>
              </div>
              <div class="right">
                <img style="width:100%" src="~@/assets/gateway/05.png" alt="">
              </div>
            </div>
            <div class="item">
              <div class="left">
                <h2 style="margin-top:0">编程、调试功能</h2>
                <p>编程功能:网关支持简单的控制逻辑下发（编程)功能，在上位机离线状态下，也可以控制末端设备实现控制，可在部分业务场景下替换PLC、DDC的角色;</p>
                <p>调试功能:简便的通过浏览器访问，就可查看每个末端设备的表头数据，在线状态，传输原始值等，非常方便项目现场进行问题的排查。</p>
              </div>
              <div class="right">
                <img style="width:100%" src="~@/assets/gateway/06.png" alt="">
              </div>
            </div>
            <div class="item">
              <div class="left">
                <h2 style="margin-top:0;margin-bottom:10px">并发能力、离线检测能力</h2>
                <p>网关底层采用IO多路复用技术和协程技术提高并发能力，单个网关可同时支持几百个末端设备的不间隔轮询采集数据(支持订阅），最新表头数据永久存储，防止掉电丢数据;</p>
                <p>网关可在200ms(可配置)内，自动检测到设备是否断电/离线，并将断电/离线信息存在虚拟地址中，供上位机查询。</p>
              </div>
              <div class="right">
                <img style="width:100%" src="~@/assets/gateway/07.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box" style="width:950px">
        <h3>工业级HLGT-CDEMOka系列网络引擎控制管理机</h3>
        <p>工业级HLGT-CDEMOka系列网络引擎控制管理机，采用X86架构，嵌入式Linux实时多任务操作系统，软件管理平台底层，通过自研领域性语言-HLGT-Oka进行开发。通过对网络引擎控制管理机灵活的进行配置，就可快速实现相应的系统功能，且支持各种子系统功能任务的自由组合。</p>
        <img  style="width:90%;margin:0 auto" src="~@/assets/gateway/08.png" alt="">
      </div>
      <img  style="width:100%;margin:0 auto" src="~@/assets/gateway/09.png" alt="">
      <div class="bg" style="margin-top:-4px">
          <div class="item-total box">
          <div class="top">
            <h2>自动化网络引擎控制管理机</h2>
            <p>河洛自创领域性语言-HLGT-Oka，利用简单高效的语法，告别笨重的PLC/DDC编程，系统框架底层做了字节码级别的性能优
            化，提高CPU运算效率，同时，采用IO协程化，提高IO并发效率。</p>
            <p>可连接大量DDC/PLC设各和子系统，引擎控制管理机提供了 可编程逻我下发、监控报著、数据记录、时间表和基础配置的功
            能，可通过以太网或无线局城网远程传输数据，支持集群模式，防止单台引1擎宕机导致系统失效。网络引擎对上位机采用
            websocket切议，对下支持modbusTCP和Bacnetlp两种协议（可订制）。</p>
            <p>websocket切议，对下支持modbusTCP和Bacnetlp两种协议（可订制）。</p>
            <p>支持3种遇调控制方案，简单条件、时间表、变量触发，即使上位机离线，也可以轻松控制集群设备。</p>
          </div>
          <div class="bottom">
            <div class="bottom-item-list row">
              <div class="item">
                <div class="img">
                  <img src="~@/assets/gateway/item1.png" alt="">
                </div>
                <div class="bottom-content">
                  <h4>傻瓜式配置，简便套用，快速实施</h4>
                  <p>所有配置的下发，编写/套用示例逻辑脚本，以及功能调试，都采用B/S架构的方式，只要插上网线，进行简单的网络配置，就可快速连接上位机，通过账户和密码进行登陆后，就可进行所有的操作，无需安装客户端，无需任何学习成本。</br>网络引擎控制管理机、子系统点位配置，均支持通过excel上传的方式，进行傻瓜式配置</br>通过浏览器访问引擎控制管理机的1P，简便套用示例脚本，即可实现复杂的控制逻辑</p>
                </div>
              </div>
              <div class="item">
                <div class="img">
                  <img style="width:15%"  src="~@/assets/gateway/item2.png" alt="">
                </div>
                <div class="bottom-content">
                  <h4>多种触发器，灵活选用</h4>
                  <p>简单条件触发器</br>支持条件触发动作，无需编写控制御本，通过简单的条件配置，即可立即实现。</br>时间表触发器</br>支持时间表触发器，根据时间值，自动触发执行一个已经编写好的脚本时间表支持复杂的表达形式，以满足各种控制需求：</br>变量触发器</br>每个点位变量下挂一个脚本，实时监测，根据变量的变化，自动触发御本，执行一系列复杂的控制逻辑。</p>
                </div>
              </div>
              <div class="item" style="margin:0">
                <div class="img">
                  <img src="~@/assets/gateway/item3.png" alt="">
                </div>
                <div class="bottom-content">
                  <h4>并发、存储能力</h4>
                  <p>底层采用IO多路复用技术和协程技术，提高并发能力，可支持上万点位亮秒级轮询：</br>支持订阅功能，设备数据一旦发生变化，就立即实时上传相关数据，并行支持定时上传功能（可配置，如：200ms, 500ms,1s, 10s, 20s等)：</br>向下毫秒级扫描下端设备（可配置)，实时记录数据，并支持保存最近24/48小时数据：</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show:false,
      pageY:0,
      img_arr:[
        {id:0,url:require('@/assets/banner/01.jpg')},
        {id:1,url:require('@/assets/banner/02.jpg')},
        {id:2,url:require('@/assets/banner/03.jpg')},
        {id:3,url:require('@/assets/banner/04.jpg')},
      ]
    }
  },
  mounted(){
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods:{
    handleScroll() {
      // console.log("滚动高度", window.pageYOffset);
      this.pageY = window.pageYOffset;
    }
  }
}
</script>
<style lang="scss" scoped>
.top-item-total{
  .item-list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .item{
      display: flex;
      justify-content: center;
    align-items: center;
      margin: 2% 0;
      .left{
        width: 80%;
        p{
          margin: 10px 0;
          line-height:1.8em
        }
      }
      .right{
        width: 35%;
        margin-left: 5%;
      }
    }
  }
}
.bg{
  background-image:url('~@/assets/gateway/bg.jpg');
  padding:1%;
  .left{
    margin-top: -5px;
    left: 60%;
  }
  .right{
    width: 40%;
  }
}
.box{
  .top{
    p{
      line-height: 1.5em;
      margin: 10px 0;
    }
  }
}

.bottom-item-list{
  margin-top: 8%;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: flex-start !important;
  .item{
    width: 33%;
    margin-right: 2%;
    background: #fff;
    border-radius: 12px;
    height: 360px;
    padding: 0 2%;
    position: relative;
    .bottom-content{
      display: flex;
      flex-direction: column; 
      p{
        line-height: 1.5em;
      }
    }
    .img{
      width: 100%;
      position: absolute;
      top: -30px;
      left: 0;
      text-align: center;
      img{
        width: 15%;
        z-index: 99;
        border-radius: 50%;
      }
    }
  }
}
.item-total{
  p{
    margin: 0;
  }
  .item-list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .item{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 4% 0;
    }
  }
}

.main{
  margin: 0% auto 2%;
  .content {
    text-align: center;
    position: relative;
    border-bottom: 2px solid #6ab0e1;
    h1 {
      font-weight: 500;
      margin-top: 0;
    }
    span {
      width: 100%;
      display: inline-block;
      padding-bottom: 5px;
    }
  }
  .content::before {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 37.5%;
      margin: -1px;
      width: 25%;
      height: 1.5px;
      background: #fe0000;
      z-index: 999;
    }
}
.block{
  margin-top: 59px;
  img{
    width: 100%;
  }
}
body{
  overflow: hidden;
}
a {
  color: #333;
}
li {
  line-height: 1.5em;
}

.container {
  width: 100%;
  .box {
    width: 880px;
    margin: 0% auto 3%;
    padding: 2% 5%;
    p{
      font-size: 14px;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      .title_2{
        animation: left 1s ease-in-out 1;
      }
      p {
        font-size: 14px;
        text-indent: 2em;
        line-height: 1.5em;
         animation: right 1s ease-in-out 1;
      }
      img {
        width: 65%;
      }
    }
    .main{
      h2{
        text-align: center;
      }
    }
    
  }
}
</style>