<template>
  <div class="container">
    <div class="block">
      <img style="width: 100%" src="~@/assets/cooperation/01.jpg" alt="" />
      <div class="plan-btn" style="width: 100%">
        <button @click="(top_dialog_show = !top_dialog_show), (mask_show = true)">
          立即参与
        </button>
      </div>
      <div class="dialog">
        <transition name="fade">
          <div class="dialog-total plan-form" style="top:10%" v-show="top_dialog_show == true">
            <div class="top">
              <h3>
                请您填写一下信息，我们会在2个工作日内与您联系，河洛联盟感谢您的入驻，并期待与您合作！
              </h3>
            </div>
            <div class="bottom">
              <keep-alive>
                <div class="total">
                  <div class="form">
                    <label for="">
                      <span><strong>*</strong>公司名称：</span>
                      <input
                        type="text"
                        v-model="company"
                        name=""
                        id=""
                        placeholder="请输入公司名称"
                      />
                    </label>
                  </div>
                  <div class="form">
                    <label for="">
                      <span><strong>*</strong>服务商类型：</span>
                      <select  name="" id="" v-model="serviceType">
                        <!-- <option value="none" selected disabled  style="color:#a9a9a9;display:none">请选择服务商</option> -->
                        <option value="1">产品认证检测服务商</option>
                        <option value="2">出口报关检测服务商</option>
                        <option value="3">资讯服务商</option>
                        <option value="4">金融服务商</option>
                        <option value="5">其他设计开发类服务商</option>
                      </select>
                    </label>
                  </div>
                  <div class="form">
                    <label for="">
                      <span><strong>*</strong>所在省份：</span>
                      <input
                        type="text"
                        name=""
                        v-model="province"
                        id=""
                        placeholder="请输入所在省份"
                      />
                    </label>
                  </div>
                  <div class="form">
                    <label for="">
                      <span><strong>*</strong>公司网址：</span>
                      <input
                        type="text"
                        name=""
                        v-model="url"
                        id=""
                        placeholder="请输入公司网址"
                      />
                    </label>
                  </div>
                  <div class="form">
                    <label for="">
                      <span><strong>*</strong>联系人姓名：</span>
                      <input
                        type="text"
                        name=""
                        v-model="name"
                        id=""
                        placeholder="请输入联系人姓名"
                      />
                    </label>
                  </div>
                  <div class="form">
                    <label for="">
                      <span><strong>*</strong>联系电话：</span>
                      <input
                        type="text"
                        name=""
                        v-model="phone"
                        id=""
                        placeholder="请输入联系电话"
                      />
                    </label>
                  </div>
                  <div style="text-align:center" class="button bottom-btn">
                    <button @click="send">立即提交</button>
                  </div>
                </div>
              </keep-alive>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="box" style="text-align: center">
      <h2 style="font-weight: 500">品牌定制与赋能</h2>
      <img style="width: 80%" src="~@/assets/cooperation/02.png" alt="" />
    </div>
    <div class="box" style="background: #ebebed">
      <h2 style="text-align: center; font-weight: 500">
        为什么要参与生态伙伴战略合作计划？
      </h2>
      <div class="item-list" style="margin: 0 15%">
        <div class="top">
          <div class="item">
            <img src="~@/assets/cooperation/item1.png" alt="" />
            <span>万亿级行业发展机遇</span>
          </div>
          <div class="item">
            <img src="~@/assets/cooperation/item2.png" alt="" />
            <span>平台生态共同提供海量供需合作机会、整合营销优势</span>
          </div>
          <div class="item">
            <img
              style="width: 23%"
              src="~@/assets/cooperation/item3.png"
              alt=""
            />
            <span>提供全球的销售渠道赋能、提高市场知名度</span>
          </div>
        </div>
        <div class="bottom">
          <div class="item">
            <img src="~@/assets/cooperation/item4.png" alt="" />
            <span>平台技术赋能研发端、提高市场竞争力</span>
          </div>
          <div class="item">
            <img src="~@/assets/cooperation/item5.png" alt="" />
            <span>独家支持，重要优质合作伙伴，提供独家合作机会和支持服务</span>
          </div>
          <div class="item">
            <img src="~@/assets/cooperation/item6.png" alt="" />
            <span
              >共同交流、共享发展机会，根据平台合作销量和相关贡献，免费获取相应期权份额</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="vip">
      <h2 style="text-align: center; font-weight: 500">招募资格会员</h2>
      <div class="item-list">
        <div class="item">
          <div class="top" style="background: #362eff">
            <h3>生产研发生态伙伴</h3>
          </div>
          <div class="bottom">
            <img src="~@/assets/cooperation/item7.png" alt="" />
            <div class="text" style="margin-left: -20px">
              <p>设备生产制造商</p>
              <p>软硬件设计开发商</p>
              <p>其他设计开发类服务商</p>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="top" style="background: #e77a01">
            <h3>销售实施生态伙伴</h3>
          </div>
          <div class="bottom">
            <img src="~@/assets/cooperation/item8.png" alt="" />
            <div class="text">
              <p>进口品牌经销/分销/代销商</p>
              <p>工程服务商/承接商/实施商</p>
              <p>产品认证检测服务商</p>
              <p>出口报关检测服务商</p>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="top" style="background: #7900b9">
            <h3>其他生态伙伴</h3>
          </div>
          <div class="bottom">
            <img src="~@/assets/cooperation/item9.png" alt="" />
            <div class="text last">
              <p>咨询服务商</p>
              <p>金融服务商</p>
              <p>各领域具有专业能力的供应商</p>
              <p>媒体合作</p>
              <p>市场合作</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img
      style="width: 100%; margin-bottom: -4px"
      src="~@/assets/cooperation/03.png"
      alt=""
    />
    <div class="join">
      <h2>立即成为河洛联盟生态伙伴，共创共享物联网未来</h2>
      <button
        class="join-btn"
        @click="(dialog_show = !dialog_show), (mask_show = true)"
      >
        <a href="javascript:">立即加入</a>
      </button>
      <div class="dialog">
        <transition name="fade">
          <div class="dialog-total" v-show="dialog_show == true">
            <div class="top">
              <h3>
                请您填写一下信息，我们会在2个工作日内与您联系，河洛联盟感谢您的入驻，并期待与您合作！
              </h3>
            </div>
            <div class="bottom">
              <keep-alive>
                <div class="total">
                  <div class="form">
                    <label for="">
                      <span><strong>*</strong>公司名称：</span>
                      <input
                        type="text"
                        v-model="company"
                        name=""
                        id=""
                        placeholder="请输入公司名称"
                      />
                    </label>
                  </div>
                  <div class="form">
                    <label for="">
                      <span><strong>*</strong>服务商类型：</span>
                      <select v-model="serviceType" name="" id="">
                        <option value="none" selected disabled style="color:#a9a9a9;display:none">请选择服务商</option>
                        <option value="1">产品认证检测服务商</option>
                        <option value="2">出口报关检测服务商</option>
                        <option value="3">资讯服务商</option>
                        <option value="4">金融服务商</option>
                        <option value="5">其他设计开发类服务商</option>
                      </select>
                    </label>
                  </div>
                  <div class="form">
                    <label for="">
                      <span><strong>*</strong>所在省份：</span>
                      <input
                        type="text"
                        name=""
                        v-model="province"
                        id=""
                        placeholder="请输入所在省份"
                      />
                    </label>
                  </div>
                  <div class="form">
                    <label for="">
                      <span><strong>*</strong>公司网址：</span>
                      <input
                        type="text"
                        name=""
                        v-model="url"
                        id=""
                        placeholder="请输入公司网址"
                      />
                    </label>
                  </div>
                  <div class="form">
                    <label for="">
                      <span><strong>*</strong>联系人姓名：</span>
                      <input
                        type="text"
                        name=""
                        v-model="name"
                        id=""
                        placeholder="请输入联系人姓名"
                      />
                    </label>
                  </div>
                  <div class="form">
                    <label for="">
                      <span><strong>*</strong>联系电话：</span>
                      <input
                        type="text"
                        name=""
                        v-model="phone"
                        id=""
                        placeholder="请输入联系电话"
                      />
                    </label>
                  </div>
                  <div class="button bottom-btn">
                    <button @click="send">立即提交</button>
                  </div>
                </div>
              </keep-alive>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div
      class="mask"
      v-show="mask_show == true"
      @click="(dialog_show = false),(top_dialog_show = false),(mask_show = false)"
    ></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      top_dialog_show:false,
      dialog_show: false,
      mask_show: false,
      labelPosition: "top",
      index_act: 1,
      active_index: 1,
      pageY: 0,
      company: "",
      serviceType: "请选择服务商",
      province: "",
      name: "",
      url: "",
      phone: "",
      email:''
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    send() {
      let { company, serviceType, province, name, url, phone } = this;
      if (company == "") {
        this.$message.error("公司名称必填");
        return;
      }
      if (serviceType == "") {
        this.$message.error("服务商必填");
        return;
      }
      if (province == "") {
        this.$message.error("省份必填");
        return;
      }
      if (url == "") {
        this.$message.error("公司网址必填");
        return;
      }
      if (name == "") {
        this.$message.error("联系人姓名必填");
        return;
      }
      if (phone == "") {
        this.$message.error("联系方式必填");
        return;
      }
      var newFormData = new FormData();
      newFormData.append("company", this.company);
      newFormData.append("serviceType", this.serviceType);
      newFormData.append("province", this.province);
      newFormData.append("name", this.name);
      newFormData.append("url", this.url);
      newFormData.append("phone", this.phone);
      console.log(newFormData);
      this.$http.post('http://api.hooloe.com/hl/add_apply',newFormData)
      .then(res=>{
        console.log(res)
        if(res.code == 200){
          this.$message({
            message: "提交成功",
            type: "success",
          });
          this.dialog_show = false
          this.top_dialog_show = false
          this.mask_show = false
        }
        else{
          this.$message.error(res.msg);
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    change(index) {
      this.active_index = index;
      console.log(index);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleScroll() {
      // console.log("滚动高度", window.pageYOffset);
      this.pageY = window.pageYOffset;
    },
  },
};
</script>
<style lang="scss" scoped>
.plan-form{
  position: absolute;
  left: 45%;
  h3 {
    background: #168eff;
    color: #fff;
    font-weight: 500;
    padding: 3% 10%;
    margin-top: 0;
  }

  .total {
    width: 650px;
    margin: 0 auto;
    padding: 1% 2% 2% 0;
  }
  .form {
    margin: 25px 0;
    text-align: right;
    span {
      color: #333;
    }
    strong {
      color: #ff0000;
    }
    input {
      width: 500px;
      border-radius: 20px;
      padding: 10px;
      border-style: solid;
      border-color: #dce0e1;
    }
    select{
      width: 524px;
      border-radius: 20px;
      padding: 10px;
      color: #666;
      border: 2px solid #dce0e1;
      border-style: solid;
      border-color: #dce0e1;
      option{
        border: none;
      }
    }
     select::-ms-expand {
            display: none;
        }
    input:focus {
      border: 2px solid #168eff;
      outline: none;
    }
    select:focus{
      border: 2px solid #168eff;
      outline: none;
      color: #333;
    }
  }
}
.plan-btn {
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 25%;
  button {
    padding: 10px 40px;
    background: none;
    border-radius: 8px;
    border: 1px solid #fff;
    color: #fff;
  }
  button:hover {
    cursor: pointer;
    background: hsl(0, 0%, 100%, 0.1);
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
.dialog-total {
  width: 1000px;
  position: absolute;
  top: -150%;
  left: 17%;
  z-index: 99;
  background: #fff;
}
.dialog {
  h3 {
    background: #168eff;
    color: #fff;
    font-weight: 500;
    padding: 3% 10%;
    margin-top: 0;
  }

  .total {
    width: 650px;
    margin: 0 auto;
    padding: 1% 2% 2% 0;
  }
  .form {
    margin: 25px 0;
    text-align: right;
    span {
      color: #333;
    }
    strong {
      color: #ff0000;
    }
    input {
      width: 500px;
      border-radius: 20px;
      padding: 10px;
      border-style: solid;
      border-color: #dce0e1;
    }
    input:focus {
      border: 2px solid #168eff;
      outline: none;
    }
    select{
      width: 524px;
      border-radius: 20px;
      padding: 10px;
      color: #666;
      border: 2px solid #dce0e1;
      border-style: solid;
      border-color: #dce0e1;
      option{
        border: none;
      }
    }
     select::-ms-expand {
            display: none;
        }
    input:focus {
      border: 2px solid #168eff;
      outline: none;
    }
    select:focus{
      border: 2px solid #168eff;
      outline: none;
      color: #333;
    }
  }
  .last {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    input {
      width: 350px !important;
    }
    .btn {
      border-radius: 20px;
      border: 2px solid #dce0e1;
      padding: 10px 25px;
      font-size: 14px;
      margin-left: 25px;
      background: #fff;
    }
    .btn:hover {
      cursor: pointer;
      background: #168eff;
      color: #fff;
      border-color: #168eff;
    }
  }
}
.block {
  margin-top: 59px;
  width: 100%;
  position: relative;
  img {
    width: 100%;
  }
}
.textarea {
  width: 500px !important;
  margin-right: 80px !important;
}
.active {
  background: rgb(233, 233, 233);
}
a {
  color: #333;
}
li {
  line-height: 1.5em;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
.btn {
  text-align: center !important;
  margin-left: 0;
}
.container {
  width: 100%;
  .box {
    width: 100%;
    margin: 2% auto 0;
    padding: 2% 0;
    .item-list {
      display: flex;
      flex-direction: column;
      .top,
      .bottom {
        display: flex;
        justify-content: center;
        margin-top: 5%;
      }
      .item {
        width: 195px;
        text-align: center;
        margin: 0 7%;
        img {
          width: 30%;
        }
        span {
          display: block;
          font-size: 14px;
          color: #333;
        }
      }
      .bottom {
        padding-bottom: 5%;
      }
    }
  }
}
.vip {
  padding: 5% 0;
  .item-list {
    display: flex;
    justify-content: center;
    margin: 0 15%;
    .item {
      width: 25%;
      margin: 0 5%;
      h3 {
        padding: 3% 2%;
        text-align: center;
        color: #fff;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 0;
      }
      .top {
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
      }
      .bottom {
        border-top: none;
        border: 1px solid #dbe1df;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 10px;
        height: 90%;
        p {
          color: #615f60;
        }
        img {
          width: 20%;
          margin: 15px 0 0px;
        }
        .text {
          text-align: left !important;
        }
        .last p {
          margin: 8px 0;
        }
        .last {
          padding: 8px 0 0px 15px;
        }
      }
    }
  }
}
.join {
  background: #ebebed;
  text-align: center;
  position: relative;
  h2 {
    margin: 0% 0 2%;
    padding-top: 3%;
    font-weight: 500;
  }
  .join-btn {
    margin: 0 0 5%;
    padding: 15px 45px;
    border: none;
    background: linear-gradient(to right, #f7dd6c, #ca581a);
    font-size: 18px;
    a {
      color: #fff;
    }
  }
  button:hover {
    cursor: pointer;
  }
  padding: 0;
}
.bottom-btn {
  button {
    background: hsl(209, 100%, 54%);
    color: #fff;
    padding: 15px 55px;
    margin: 2% auto 5%;
    border: none;
    font-size: 16px;
  }
  button:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}
</style>