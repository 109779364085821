<template>
  <div class="container">
    <div class="block">
      <el-carousel height="489px" :interval="4000">
        <el-carousel-item v-for="item in img_arr" :key="item.id">
          <img :src="item.url" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="main">
      <div class="content" style="margin-top:25px">
        <h1
          class="animate__animated"
          style="margin-bottom: 0px"
        >
          <a style="color:#c0012c;font-size:27px" href="javaScript:void(0)">免费定制开发</a>
        </h1>
        <span
          style="color:#c0012c;font-size:16px"
          class="animate__animated"
          >Free custom development</span
        >
      </div>
      <img style="width:100%" src="~@/assets/office/01.png" alt="">
      <div class="content">
        <h1
          class="animate__animated"
          style="margin-bottom: 0px"
        >
          <a style="color:#c0012c;font-size:27px" href="javaScript:void(0)">技术实力</a>
        </h1>
        <span
          style="color:#c0012c;font-size:16px"
          class="animate__animated"
          >Technical strength</span
        >
      </div>
      <div style="background:#ebebed;width:100%;text-align:center;padding:20px 0">
        <img style="width:70%" src="~@/assets/office/02.png" alt="">
      </div>
      
      <div v-if="pos>800" style="background:#fff;width:100%;text-align:center;padding:20px 0 0px">
        <div class="content">
        <h1
          class="animate__animated"
          style="margin-bottom: 0px"
        >
          <a style="color:#c0012c;font-size:27px" href="javaScript:void(0)">我们的业务范围</a>
        </h1>
        <span
          style="color:#c0012c;font-size:16px"
          class="animate__animated"
          >Our business scope</span
        >
      </div>
        <img style="width:100%" src="~@/assets/office/03.png" alt="">
        <div class="content">
        <h1
          class="animate__animated"
          style="margin-bottom: 0px"
        >
          <a style="color:#c0012c;font-size:27px" href="javaScript:void(0)">我们的产品和服务定位</a>
        </h1>
        <span
          style="color:#c0012c;font-size:16px"
          class="animate__animated"
          >Our product and service positioning</span
        >
      </div>
        <!-- <img style="width:100%;position:relative" src="~@/assets/office/031.png" alt=""> -->
        <div class="text">
          <div class="text-list">
            <div style="width:24%  !important;padding:0 0.3%">
              <p>软硬件不仅稳定可靠，而且具有超低价（工业级，保证全网最低价）</p>
            </div>
            <div style="width:24% !important;padding:0 0 0 0.8%">
              <p style="letter-spacing: 0.6px;">自研开发语言系统框架HLGT-Oka，自研符合物联网行业需求的大吞吐量数据库HLGT-ODB,百亿级数据随机分页,毫秒级近实时多维度复杂查询,每秒10W的写入速度,并有极高压缩比</p>
            </div>
            <div style="width:24% !important;padding:0 0 0 1%">
              <p>快速工程实施(效率):系统软硬件支持快速实施(所有软硬件配置,支持excel导入)，具体系统项目，一旦完成excel表格的导入，就完成了系统的搭建,同时确保稳定运行</p>
            </div>
            <div style="width:24% !important;padding:0 0 0 1%">
              <p>共赢合作、长期合作,软硬件免费订制开发、免费培训、持续低价保证</p>
            </div>
            <div style="width:24% !important;padding:0 0 0 1%">
              <p>股权开放和核心技术开放(多种规则,符合条件,低价/免费开放)、长期技术支持和技术升级</p>
            </div>
          </div>
        </div>
      </div>
      <div class="honor">
        <div class="content">
          <h1
            class="animate__animated"
            style="margin-bottom: 0px"
          >
            <a style="color:#c0012c;font-size:27px" href="javaScript:void(0)">荣誉资质</a>
          </h1>
          <span
            style="color:#c0012c;font-size:16px"
            class="animate__animated"
            >Honorary qualification</span
          >
        </div>
        <div class="hornor_total">
            <div class="carousel">
              <div>
                <ul :class="tran==true?'tran':''" >
                  <li v-for="(item,index) in hornor_img"><img v-lazy='item.url' alt=""></li>
                </ul>
              </div>
            </div>
          <div style="text-align:center;margin-top:35px">
            <h2
              class="animate__animated"
              style="margin-bottom: 5px; font-weight: 500;text-align:center"
            >
              <a @click="(dialog_show = !dialog_show), (mask_show = true)" style="border-radius:24px;background:#bf002c;text-align:center;color:#fff;font-weight:300;padding:8px 35px" href="javaScript:void(0)"
                >加入河洛联盟，享受免费、快速、订制开发服务！</a
              >
            </h2>
          </div>
        </div>
        <div class="dialog">
        <transition name="fade">
          <div class="dialog-total" v-show="dialog_show == true">
            <div class="top">
              <h3>
                请您填写一下信息，我们会在2个工作日内与您联系，河洛联盟感谢您的入驻，并期待与您合作！
              </h3>
            </div>
            <div class="bottom">
              <keep-alive>
                <div class="total">
                  <div class="form">
                    <label for="">
                      <span><strong>*</strong>公司名称：</span>
                      <input
                        type="text"
                        v-model="company"
                        name=""
                        id=""
                        placeholder="请输入公司名称"
                      />
                    </label>
                  </div>
                  <div class="form">
                    <label for="">
                      <span><strong>*</strong>服务商类型：</span>
                      <select v-model="serviceType" name="" id="">
                        <option value="none" selected disabled style="color:#a9a9a9;display:none">请选择服务商</option>
                        <option value="1">产品认证检测服务商</option>
                        <option value="2">出口报关检测服务商</option>
                        <option value="3">资讯服务商</option>
                        <option value="4">金融服务商</option>
                        <option value="5">其他设计开发类服务商</option>
                      </select>
                    </label>
                  </div>
                  <div class="form">
                    <label for="">
                      <span><strong>*</strong>所在省份：</span>
                      <input
                        type="text"
                        name=""
                        v-model="province"
                        id=""
                        placeholder="请输入所在省份"
                      />
                    </label>
                  </div>
                  <div class="form">
                    <label for="">
                      <span><strong>*</strong>公司网址：</span>
                      <input
                        type="text"
                        name=""
                        v-model="url"
                        id=""
                        placeholder="请输入公司网址"
                      />
                    </label>
                  </div>
                  <div class="form">
                    <label for="">
                      <span><strong>*</strong>联系人姓名：</span>
                      <input
                        type="text"
                        name=""
                        v-model="name"
                        id=""
                        placeholder="请输入联系人姓名"
                      />
                    </label>
                  </div>
                  <div class="form">
                    <label for="">
                      <span><strong>*</strong>联系电话：</span>
                      <input
                        type="text"
                        name=""
                        v-model="phone"
                        id=""
                        placeholder="请输入联系电话"
                      />
                    </label>
                  </div>
                  <div class="button bottom-btn">
                    <button @click="send">立即提交</button>
                  </div>
                </div>
              </keep-alive>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div
      class="mask"
      v-show="mask_show == true"
      @click="(dialog_show = false), (mask_show = false)"
    ></div>
  </div>
  </div>
</template>
<style lang="scss" scoped>
.text{
  z-index: 2;
  background: url('~@/assets/office/031.png') no-repeat;
  background-size: 100%;
  height: 20%;
  min-height: 470px;
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  .text-list{
    width: 68%;
    height: 20%;
    // height: 100%;
    display: flex;
    position: absolute;
    bottom: 32%;
    div{
      width: 25% !important;
      margin: 0 2%;
      p{
        letter-spacing: 1.5px;
        font-size: 14px;
        color: #333;
        text-align: left;
      }
    }
  }
}
.bottom-btn {
  text-align: center;
  button {
    background: hsl(209, 100%, 54%);
    color: #fff;
    padding: 15px 55px;
    margin: 2% auto 5%;
    border: none;
    font-size: 16px;
  }
  button:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}
.dialog-total {
  width: 1000px;
  position: absolute;
  top: -25%;
  left: 17%;
  z-index: 99;
  background: #fff;
}
.dialog {
  h3 {
    background: #168eff;
    color: #fff;
    font-weight: 500;
    padding: 3% 10%;
    margin-top: 0;
  }

  .total {
    width: 650px;
    margin: 0 auto;
    padding: 1% 2% 2% 0;
  }
  .form {
    margin: 25px 0;
    text-align: right;
    span {
      color: #333;
    }
    strong {
      color: #ff0000;
    }
    input {
      width: 500px;
      border-radius: 20px;
      padding: 10px;
      border-style: solid;
      border-color: #dce0e1;
    }
    input:focus {
      border: 2px solid #168eff;
      outline: none;
    }
    select{
      width: 524px;
      border-radius: 20px;
      padding: 10px;
      color: #666;
      border: 2px solid #dce0e1;
      border-style: solid;
      border-color: #dce0e1;
      option{
        border: none;
      }
    }
     select::-ms-expand {
            display: none;
        }
    input:focus {
      border: 2px solid #168eff;
      outline: none;
    }
    select:focus{
      border: 2px solid #168eff;
      outline: none;
      color: #333;
    }
  }
  .last {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    input {
      width: 350px !important;
    }
    .btn {
      border-radius: 20px;
      border: 2px solid #dce0e1;
      padding: 10px 25px;
      font-size: 14px;
      margin-left: 25px;
      background: #fff;
    }
    .btn:hover {
      cursor: pointer;
      background: #168eff;
      color: #fff;
      border-color: #168eff;
    }
  }
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
.hornor_total{
  background: #ebebed;
  width: 100%;
  padding: 15px 0 0px;
  height: 395px;
}
.tran{
  transition: all 60s linear;
  left: -5527px !important;
}
.tran_active{
  transition: none;
}
a {
  text-decoration: none;
  color: #333;
}
li {
  list-style-type: none;
  display: inline-block;
}
ul {
  padding: 0;
  margin: 0;
}
.block{
  margin-top: 59px;
  img{
    width: 100%;
  }
}
.main {
  margin: 0% auto 2%;
  .content {
    text-align: center;
    position: relative;
    border-bottom: 2px solid #6ab0e1;
    h1 {
      font-weight: 500;
      margin-top: 0;
    }
    span {
      width: 100%;
      display: inline-block;
      padding-bottom: 5px;
    }
  }
  .content::before {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 37.5%;
      margin: -1px;
      width: 25%;
      height: 1.5px;
      background: #fe0000;
      z-index: 1;
    }
  .honor {
    width: 100%;
    margin: 0% auto;
    position: relative;
    height: 480px;
    // overflow: hidden;
    .carousel{
      text-align: center;
      background: #ebebed;
      div {
				width: 1300px;
				display: inline-block;
				overflow: hidden;
				position: relative;
        white-space:nowrap;
			}
			ul {
				height: 240px;
				margin-top: 30px;
				position: relative;
				width: 3000px;
				left: 0;
				margin-left: 0!important;
        white-space:nowrap;
			}
			li {
				width: 5%;
				padding: 0!important;
				text-align: center;
        margin: 0 8px;
        img{
          width: 100%;
        }
			}
    }
  }
}
</style>
<script>
export default {
  data() {
    return {
      tran:false,
      dialog_show: false,
      mask_show: false,
      company: "",
      serviceType: "请选择服务商",
      province: "",
      name: "",
      url: "",
      phone: "",
      email:'',
      show: {
        first_show: false,
        second_show: false,
        third_show: false,
        fourth_show: false,
        fifth_show: false,
        sixth_show: false,
        seventh_show: false,
        eighth_show: false,
        ninth_show: false,
        one_show: false,
        two_show: false,
      },
      pos: 0,
      current_index:0,
      img_arr:[
        {id:0,url:require('@/assets/banner/01.jpg')},
        {id:1,url:require('@/assets/banner/02.jpg')},
        {id:2,url:require('@/assets/banner/03.jpg')},
        {id:3,url:require('@/assets/banner/04.jpg')},
      ],
      hornor_img:[
        {id:0,url:require('@/assets/honor/15.jpg')},
        {id:1,url:require('@/assets/honor/17.jpg')},
        {id:2,url:require('@/assets/honor/18.jpg')},
        {id:3,url:require('@/assets/honor/01.jpg')},
        {id:4,url:require('@/assets/honor/02.jpg')},
        {id:5,url:require('@/assets/honor/03.jpg')},
        {id:6,url:require('@/assets/honor/04.jpg')},
        {id:7,url:require('@/assets/honor/05.jpg')},
        {id:8,url:require('@/assets/honor/06.jpg')},
        {id:9,url:require('@/assets/honor/24.jpg')},
        {id:10,url:require('@/assets/honor/07.jpg')},
        {id:11,url:require('@/assets/honor/08.jpg')},
        {id:12,url:require('@/assets/honor/21.png')},
        {id:13,url:require('@/assets/honor/09.jpg')},
        {id:14,url:require('@/assets/honor/10.jpg')},
        {id:15,url:require('@/assets/honor/11.jpg')},
        {id:16,url:require('@/assets/honor/12.png')},
        {id:17,url:require('@/assets/honor/13.png')},
        {id:18,url:require('@/assets/honor/14.jpg')},
        {id:19,url:require('@/assets/honor/16.png')},
        {id:20,url:require('@/assets/honor/19.png')},
        {id:21,url:require('@/assets/honor/22.png')},
        {id:22,url:require('@/assets/honor/23.png')},
        {id:23,url:require('@/assets/honor/25.jpg')},
        {id:24,url:require('@/assets/honor/27.png')},
        {id:25,url:require('@/assets/honor/28.png')},
        {id:26,url:require('@/assets/honor/29.png')},
        {id:27,url:require('@/assets/honor/31.png')},
        {id:28,url:require('@/assets/honor/34.png')},
        {id:29,url:require('@/assets/honor/35.png')},
        {id:30,url:require('@/assets/honor/36.png')},
        {id:31,url:require('@/assets/honor/37.png')},
        {id:32,url:require('@/assets/honor/38.png')},
        {id:33,url:require('@/assets/honor/39.png')},
        {id:34,url:require('@/assets/honor/40.png')},
        {id:35,url:require('@/assets/honor/41.jpg')},
      ]
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      this.pos = window.pageYOffset;
      // console.log(this.pos);
      if(this.pos>1510){
        this.tran = true
      }else{
        this.tran = false
      }
      console.log(this.tran);
    },
    send() {
      let { company, serviceType, province, name, url, phone } = this;
      if (company == "") {
        this.$message.error("公司名称必填");
        return;
      }
      if (serviceType == "") {
        this.$message.error("服务商必填");
        return;
      }
      if (province == "") {
        this.$message.error("省份必填");
        return;
      }
      if (url == "") {
        this.$message.error("公司网址必填");
        return;
      }
      if (name == "") {
        this.$message.error("联系人姓名必填");
        return;
      }
      if (phone == "") {
        this.$message.error("联系方式必填");
        return;
      }
      var newFormData = new FormData();
      newFormData.append("company", this.company);
      newFormData.append("serviceType", this.serviceType);
      newFormData.append("province", this.province);
      newFormData.append("name", this.name);
      newFormData.append("url", this.url);
      newFormData.append("phone", this.phone);
      console.log(newFormData);
      this.$http.post('http://api.hooloe.com/hl/add_apply',newFormData)
      .then(res=>{
        console.log(res)
        if(res.code == 200){
          this.$message({
            message: "提交成功",
            type: "success",
          });
          this.dialog_show = false
          this.top_dialog_show = false
          this.mask_show = false
        }
        else{
          this.$message.error(res.msg);
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    second_title() {},
  },
};
</script>
