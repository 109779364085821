<template>
    <div class="container">
        <!-- <div class="block">
            <el-carousel height="600px" :autoplay="false">
                <el-carousel-item v-for="item in 3" :key="item">
                <h3 class="medium">{{ item }}</h3>
                </el-carousel-item>
            </el-carousel>
        </div> -->
        <div class="content">
            <div class="total" style="overflow:hidden">
                <div class="header animat_left" style="overflow:hidden">
                    <h1 style="font-weight:500">关于我们</h1>
                </div>
                <div class="box animat_right">
                    <p style="letter-spacing:1px">上海河洛实业有限公司于2011年初正式成立，是专业提供智能化综合解决方案，及相关系统软硬件的开发、生产（委托江苏河洛）、销售、实施和技术综合服务于一体的高新企业。</p>
                    <p style="letter-spacing:1px">河洛以拥有国家知识产权专利的智能化设备和专业领先的系统产品为核心；以拥有高效创新的技术研发团队，和经验丰富的工程服务团队为根本；长期致力于智能化管理解决方案的设计、开发、实施与服务，为客户提供专业、科学、全面的智能化综合解决方案。</p>
                    <p style="letter-spacing:1px">河洛长期提供智能化软硬件产品的定制开发、生产和系统实施服务，并于2020年初，创造性的开发出全维度综合决策管理系统云平台软件HLGT-IDMS（包含总多子系统模块，并有机集成，支持任意组合），以及面向市场的HLGT-EMOka系列通讯网关（支持对各种通讯协议的订制开发）和网络引擎控制器，既可支持智能化仪器仪表生产厂家和工程类公司，对智能化通用系统的软硬件，进行快速定制开发、快速实施实现，以及确保整个系统的可靠性、稳定性，又可满足终端用户对于系统解决方案个性化、定制化的实际需求，获得了市场的积极认可。</p>
                    <p style="letter-spacing:1px">一直以来，河洛公司始终坚持“智能科技，因河洛而有所前进”为目标，秉承“客户的满意与成功，是度量我们工作成绩最重要标尺”的服务理念，我们相信，通过河洛所有员工的共同努力，河洛将成为客户和供应商的最佳选择。</p>
                    <p style="letter-spacing:1px">我们本着诚信合作、认真负责、共同成就的合作精神，坚持高效创新、永不满足、服务客户的企业理念，凭借智能化领域内专业权威的领先技术，为合作伙伴提供专业化、定制化、综合化智能管理系统全面解决方案，让终端用户的管理更智能、更专业、更科学。</p>
                </div>
                <div class="box">
                    <div class="bottom">
                        <div class="left  animat_right">
                            <baidu-map class="map" @ready="mapready"></baidu-map>
                        </div>
                        <div class="right  animat_left">
                            <div class="right-top">
                                <h2>联系方式：</h2>
                                <h3>上海河洛实业有限公司</h3>
                                <p>地址：上海市静安区江场西路180号7号楼9A05</p>
                                <p>电话：021-56428021</p>
                                <p>传真：021-56428022</p>
                                <h3>江苏河洛电子科技有限公司</h3>
                                <p>地址：江苏张家港杨舍镇泗港路章卿村工业区</p>
                                <p>电话：052-58389920</p>
                                <h3>服务热线：</h3>
                                <p>400-110-6808</p>
                                <p>网址：www.hooloe.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.animat_left{
    animation: left 1s ease-in-out 1;
}   
.animat_right{
    animation: right 1s ease-in-out 1;
}   
@keyframes left{
   0% {
      transform: translateX(-2000px) translateY(-500px);
      opacity: 0;
   }
   100% {
      transform: translateX(0px) translateY(0px);
      opacity: 1;
   }
}
@keyframes right{
   0% {
      transform: translateX(2000px);
      // opacity: 0;
   }
   100% {
      transform: translateX(0px);
      padding: 0;
      // opacity: 1;
   }
}
.map {
  height: 430px;
  width: 100%;
  padding-top: 2%;
  margin: 0 auto;
}
a {
  color: #333;
}
li {
  line-height: 1.5em;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.container{
    width: 100%;
    .content{
        margin: 0% auto;
        padding: 7% 10% 3%;
        background: #f8f8f8;
        .total{
            padding: 3% 5%;
            background: #fff;
            .header{
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 3%;
                a{
                    width: 25%;
                    padding: 19.5px 25px;
                    border-bottom: 1px solid #ededed;
                    font-size: 20px;
                    font-weight: 500;
                }
                a:hover{
                    color: red;
                    border-color: red;
                }
            }
            .box{
                display: flex;
                flex-direction: column;
                .top{
                    display: flex;
                    justify-content: center;
                    margin-bottom: 3%;
                    .item{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-content: center;
                        margin: 0 10%;
                        div{
                            text-align: center;
                        }
                        img{
                            width: 60%;
                        }
                        p{
                            margin: 0;
                            text-align: center;
                            text-indent: 0;

                        }
                        h3{
                            text-align: center;
                            font-size: 20px;
                            margin-bottom: 5px;
                            font-weight: 500;
                        }
                    }
                }
                .bottom{
                    display: flex;
                    .left{
                        width: 55%;
                        margin-right: 3%;
                    }
                    .right{
                        width: 35%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .right-top{
                            h2{
                                font-weight: 500;
                                margin: 8px 0;
                            }
                        }
                        p{
                            font-size: 18px;
                            text-indent: 0;
                            margin: 8px 0;
                        }
                        h3{
                            font-weight: 600;
                        }
                    }
                }
                p{
                    text-indent: 2em;
                    line-height: 1.5em;
                }
        }
        }
        
    }
    .active{
        color: #ff0000;
        border-bottom: 1px solid #ff0000;
        // border-color: #ff0000;
    }
}

</style>
<script>
export default {
    data() {
        return {
            active_index:0,
            title:[
                {id:0,title:'公司介绍'},
                {id:1,title:'关于我们'}
            ],
            content_index:0
        }
    },
    mounted(){
        window.addEventListener("scroll", this.handleScroll, true);
    },
    methods: {
        handleScroll() {
            // console.log("滚动高度", window.pageYOffset);
            this.pageY = window.pageYOffset;
        },
        changeIndex(index){
            this.active_index = index
            this.content_index = index
        },
        mapready({ BMap, map }) {
            // 选择一个经纬度作为中心点
            var point = new BMap.Point(121.452003, 31.304616);
            var marker4 = new BMap.Marker(new BMap.Point(121.451886, 31.304868));
            map.centerAndZoom(point, 13);
            map.addOverlay(marker4);
            map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
            var scaleCtrl = new BMap.ScaleControl(); // 添加比例尺控件
            map.addControl(scaleCtrl);
        },
    },
}
</script>